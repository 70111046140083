import { useMemo } from "react";
import { SwiperSlide } from "swiper/react";
import chunk from "lodash.chunk";
import useClientWindowSize from "@/hooks/useClientWindowSize";
import BasicSwiper from "@/components/BasicSwiper";

interface Props<T> {
  id: string;
  items: T[];
  render: (item: T) => JSX.Element;
  swiperClassName?: string;
}

const ChunkedSwiper = <T,>({
  id,
  items,
  render,
  swiperClassName,
}: Props<T>) => {
  // 通常1つだが1200以下になると、小さくなっていくようにする
  const { windowWidth } = useClientWindowSize();
  const numOfChunk = useMemo(() => {
    if (windowWidth > 1280) return 8;

    if (windowWidth > 960) return 6;

    if (windowWidth > 800) return 4;

    return 2;
  }, [windowWidth]);

  // itemsをXつずつに分割
  const chunkedItems = useMemo<T[][]>(
    () => chunk(items, numOfChunk),
    [items, numOfChunk],
  );

  return (
    <BasicSwiper
      id={id}
      swiperProps={{
        spaceBetween: 12,
        slidesPerView: 1,
        slidesPerGroup: 1,
        className: swiperClassName,
      }}
    >
      {chunkedItems.map((items, index) => (
        <SwiperSlide key={index}>
          <div
            className="grid gap-12"
            style={{
              gridTemplateColumns: `repeat(${numOfChunk / 2}, minmax(0, 1fr))`,
            }}
          >
            {items.map((item) => (
              <>{render(item)}</>
            ))}
          </div>
        </SwiperSlide>
      ))}
    </BasicSwiper>
  );
};
export default ChunkedSwiper;
