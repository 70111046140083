import { RootState } from "@/stores/rootReducer";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { SWRConfiguration } from "swr";
import useSWRInfinite from "swr/infinite";

type PageResponse<T> = {
  results?: T[];
  cursor?: number | string;
  has_next: boolean;
  total_count?: number;
};

type FetcherArgs = {
  key: string;
  limit: number;
  page: number;
  cursor?: number | string;
};

export type PageFetcher<T> = (values: FetcherArgs) => Promise<PageResponse<T>>;

export interface PaginationResponse<T> {
  data: T[];
  hasNext: boolean;
  dataLength: number;
  next: () => void;
  addItem: (item: T, isFirst: boolean) => void;
  updateItem: (item: T, shouldRevalidate?: boolean) => void;
  removeItem: (item: T) => void;
  revalidate: () => Promise<boolean>;
  notFound: boolean;
  totalCount: number;
  isLoading: boolean;
  error: any;
}

interface PaginationOptions {
  waitForAuth?: boolean;
}

const usePagination = <T extends { id: number | string }>(
  path: string,
  limit: number,
  fetcher: PageFetcher<T>,
  options: PaginationOptions = {},
  config: SWRConfiguration = {},
): PaginationResponse<T> => {
  const { isChanged } = useSelector((state: RootState) => state.auth);
  const { data, size, setSize, mutate, isLoading, error } = useSWRInfinite<
    PageResponse<T>
  >(
    (pageIndex, previousPageData) => {
      if (options.waitForAuth && !isChanged) {
        return null;
      }

      const page = pageIndex + 1;

      if (pageIndex === 0) {
        return {
          key: `${path}?limit=${limit}&page=${page}`,
          limit,
          page,
        };
      }

      return {
        key: `${path}?limit=${limit}&page=${page}`,
        limit,
        page,
        cursor: previousPageData.cursor,
      };
    },
    fetcher,
    { revalidateFirstPage: false, ...config },
  );

  const dataLength = data
    ? data
        .map((d) => d.results?.length || 0)
        .reduce((acc, current) => acc + current, 0)
    : 0;

  const hasNext = data
    ? data.length > 0 && data[data.length - 1].has_next
    : true;

  const totalCount = data
    ? data.length > 0
      ? data[data.length - 1].total_count
      : 0
    : 0;

  const flatData = data ? data.map((d) => d.results).flat() : [];

  const addItem = (item: T, isFirst = true) => {
    if (isFirst) {
      data[0].results.unshift(item);
    } else {
      data[data.length - 1].results.unshift(item);
    }
    mutate(data);
  };

  const updateItem = useCallback(
    (item: T, shouldRevalidate = false) => {
      mutate((data: PageResponse<T>[]) => {
        return data.map((d) => {
          if (!d.results) {
            return d;
          }

          const index = d.results.findIndex((i) => i.id === item.id);

          if (index === -1) return d;

          const updatedResults = d.results.slice();
          updatedResults[index] = item;

          return { ...d, results: updatedResults };
        });
      }, shouldRevalidate);
    },
    [mutate],
  );

  const removeItem = (item: T) => {
    mutate((data: PageResponse<T>[]) => {
      return data.map((d) => {
        if (d.results) {
          d.results = d.results.filter((i) => i.id != item.id);
        }

        return d;
      });
    }, true);
  };

  const revalidate = async () => {
    mutate();

    return true;
  };

  return {
    data: flatData,
    dataLength,
    totalCount,
    hasNext,
    next: () => {
      setSize(size + 1);
    },
    addItem,
    updateItem,
    removeItem,
    revalidate,
    notFound: data && data[0].results.length === 0,
    isLoading,
    error,
  };
};

export default usePagination;
