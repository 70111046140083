import { FC, useCallback, useState } from "react";
import { Swiper, SwiperClass, SwiperProps } from "swiper/react";
import { Navigation, Pagination, FreeMode } from "swiper/modules";
import clsx from "clsx";
import SwiperPrevButton from "@/components/SwiperPrevButton";
import SwiperNextButton from "@/components/SwiperNextButton";
import useDevice from "@/hooks/useDevice";
import "swiper/css";
import "swiper/css/free-mode";

interface Props {
  id: string;
  children: React.ReactNode;
  swiperProps?: SwiperProps;
  portalId?: string;
}

const BasicSwiper: FC<Props> = ({ id, children, swiperProps, portalId }) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  const next = useCallback(() => {
    if (!swiper) return;

    swiper.slideNext();
  }, [swiper]);

  const prev = useCallback(() => {
    if (!swiper) return;

    swiper.slidePrev();
  }, [swiper]);

  const { isSp } = useDevice();

  return (
    <div className="relative">
      <Swiper
        className="BasicSwiper"
        onSwiper={setSwiper}
        navigation={{
          prevEl: `.SectionSwiper_prev_${id}`,
          nextEl: `.SectionSwiper_next_${id}`,
          disabledClass: "opacity-0",
        }}
        modules={[Navigation, Pagination, FreeMode]}
        {...swiperProps}
      >
        {children}
      </Swiper>
      <div
        className={clsx(
          `SectionSwiper_prev_${id}`,
          "absolute left-0 top-1/2 transform translate-x-1/4 -translate-y-1/2 z-10 transition-opacity",
          "laptop:-translate-x-1/2",
        )}
      >
        <SwiperPrevButton onClick={prev} size={isSp ? "small" : "large"} />
      </div>

      <div
        className={clsx(
          `SectionSwiper_next_${id}`,
          "absolute right-0 top-1/2 transform -translate-x-1/4 -translate-y-1/2 z-10 transition-opacity",
          "laptop:translate-x-1/2",
        )}
      >
        <SwiperNextButton onClick={next} size={isSp ? "small" : "large"} />
      </div>

      {/* 吹き出しを描画するDOM */}
      {portalId && (
        <div
          className={clsx(
            "absolute inset-0 z-20",
            "pointer-events-none", // マウスホバーに反応しなくなるので親はマウスに反応しないようにする
            "[&>*]:pointer-events-auto", // 子はマウスに反応するようにする
          )}
          id={portalId}
        />
      )}
    </div>
  );
};
export default BasicSwiper;
