import { FC } from "react";
import IconButton from "@/components/IconButton";
import { IconButtonSize } from "@/components/IconButton/types/IconButtonSize";
import IconArrowBack from "@/assets/imgs/svg/icon_arrow_back.svg";

interface Props {
  size: IconButtonSize;
  onClick?: () => void;
}

const SwiperPrevButton: FC<Props> = ({ size, onClick }) => {
  return (
    <IconButton
      size={size}
      onClick={onClick}
      aria-label=""
      rounded
      variant="secondary"
      className="shadow-md"
    >
      <IconArrowBack />
    </IconButton>
  );
};
export default SwiperPrevButton;
