import TheFooter from "@/components/TheFooter";
import TheHeader from "@/components/TheHeader";
import { FC } from "react";
import BaseLayout, { LayoutProps } from "./BaseLayout";
import dynamic from "next/dynamic";
import EmailVerificationAlert from "@/features/email_verification/components/EmailVerificationAlert";
import clsx from "clsx";

const DynamicModalShowDetail = dynamic(
  () => import("@/components/ModalShowDetail"),
);

const DefaultLayout: FC<
  LayoutProps & { hiddenFooter?: boolean; className?: string }
> = ({
  children,
  requiredAuth,
  show,
  hiddenFooter = false,
  className,
}): JSX.Element => {
  return (
    <BaseLayout requiredAuth={requiredAuth} show={show}>
      <div className={clsx("bg-main", className)}>
        <TheHeader />
        <div>
          <div className="min-h-screen">{children}</div>

          {!hiddenFooter && <TheFooter />}
        </div>
      </div>

      <DynamicModalShowDetail />
      <EmailVerificationAlert />
    </BaseLayout>
  );
};

export default DefaultLayout;
